import React from "react";


class UnderConstruction extends React.Component {
  render() {
    return (
      <div className="under-construction">
        <h2>Welcome to the {this.props.pageTitle} page!</h2>
        <img src="under-construction.gif" alt="this page is under construction"></img>
        <span style={ {align: 'center', paddingTop: '8px'}}>This page is under construction. Check back later.</span>
      </div>
    );
  }
}

export default UnderConstruction;
import React from "react";
import PropTypes from 'prop-types';

class About extends React.Component {
  componentDidMount() {
    this.props.setBackground('secondary');
  }

  render() {
    return (
      <div className="about">
        <h2 className="header">A little about myself...</h2>
        <div className="navList">
          <a href="#academia">Academia</a>
          <a href="#development">Software Development</a>
          <a href="#jui-jitsu">Jui-Jitsu</a>
          <a href="#dog-dad">Dog Dad</a>
          <a href="#travelling">Travelling</a>
          <a href="#chess">Chess</a>
        </div>
        <h3 id="academia">Academia</h3>
        <blockquote cite="https://en.wikiquote.org/wiki/Dr._Seuss#I_Can_Read_With_My_Eyes_Shut!_(1978)">
          <p>The more that you read, the more things you will know. The more that you learn, the more places you’ll go.</p>
          <footer>—Dr. Seuss, <cite>I Can Read with My Eyes Shut!</cite></footer>
        </blockquote>
        <p>Reading and ultimately learning is something I strive to do everyday. I consider myself a <a href="https://www.valamis.com/hub/lifelong-learning">lifetime learner</a>. I'll continue learning as long as I am alive!</p>
        <p>
          While I enjoy learning random things, like the fact that the fire hydrant patent was lost in a fire, or how to juggle because it increases grey matter in the brain, I also have done my fair share of academic study.
          In my sophomore year in high school, I learned about the dual enrollment program, which would allow a student to attend a local college and apply credits at college and high school.
          I was in, fully in. In fact, except for one class that I had to take on my high school campus, I never returned to my high school, but attended my local community college full time.
          I even went so far as to take summer classes so that I could graduate with my associates and my high school diploma at the same time. Actually, the graduation from college happened a week before the high school graduation.
        </p>
        <p>
          Why did I do all this work? Well, coming from a small town and not having too much that would jump off the page for college admissions, I thought this would be something that would.
          My dream college was the Georgia Institute of Technology. I've wanted to be a rocket scientist since I was in kindergarten.
          Turns out, this was enough to get me in. So off I go to Georgia Tech!
        </p>
        <p>
          I didn't last one full semester, as an aerospace student, that is.
          At a majors fair halfway through my first semester, the assitant dean to the math department talked me into going with the field I have always enjoyed and have excelled at, math. The next day, I changed my major to discrete mathematics.
          This ultimately led me to learning that I really enjoyed solving problems, specifically problems one could solve with a computer. A year into being a math major, a close cousin I met for the first time a Tech, explained to me that if I would switch to Computer Science and go the theory thread route, I would pretty much take all the same classes, but would get paid more once I graduated.
          I changed my major the next day.
        </p>
        <p>
          Near the end of my undegrad stint at Tech, I got exposure to the world of artificial intelligence, and specifically, machine learning. Talk about solving problems, now we're getting somewhere.
          I found I really enjoyed the types of problems machine learning brought with it. And, I found that I specifically liked the discipline of reinforcement learning. This is the field that allows Tesla cars to drive and park themselves!
        </p>
        <p>
          After graduating from Tech with my undergraduate degree in the spring of 2015, I took a software engineering role at a tech company in Marietta, GA. Finally, after 17 years of school, I was done with school and in the real world!
          The following fall, I began my masters in computer science specializing in machine learing at Georgia Tech. I wanted to do a deeper dive into machine learning and computer science in general, and I felt that the opportunity was too good to pass up.
          Fast forward to 2018 and I graduated with my masters from Tech.
        </p>
        <p>
          Since graduating a second time from Georgia Tech, I haven't taken any official college courses. I have been to some awesome software developer conferences. <a href="http://connect.tech/">Connect.Tech</a> is probably my favorite, and is highly recommended if you get the chance to go.
          I've also done my best to broaden the knowledge base I have in software engineering, learning new languages, frameworks, and technologies.
        </p>
        <p>
          I've also picked up stocks, trading, and finances in gereral. My goal with this is to become financially independent and just generally know the the near and long term future for myself and my family is financially solid.
        </p>
        <p>
          Like I said, I'll continue down this path of learning for as long as I am alive!
        </p>
        <h3 id="development">Software Development</h3>
        <blockquote cite="https://www.goodreads.com/work/quotes/3779106-clean-code-a-handbook-of-agile-software-craftsmanship-robert-c-martin">
          <p>Truth can only be found in one place: the code.</p>
          <footer>—Robert C. Martin, <cite>Clean Code: A Handbook of Agile Software Craftsmanship</cite></footer>
        </blockquote>
        <p>
          Since graduating in 2015, I have been a full time software engineer, working mainly as a full stack web developer. At my previous job, I worked primarily in C# and Javascript, using Marionette and VueJs frameworks. At my current job, I also work as a full stack web developer, where I work primarily in Ruby and Javascript, using the React and Rails frameworks.
          As far as <a href="/projects">personal projects</a>, I have a few I have worked on over the years and am currently working on. Most of them are just for my own personal use, but some are in the works I hope to be able to share or that others may find useful!
        </p>
        <p>
          I generally don't find listing all of the programming languages and technologies one is proficient at or has written a Hello World! application in is all that meaningful.
          Instead, I just like to convey the fact that I enjoy problem solving, and specifically doing so with code, computers, and technology in general.
          I have worked with several technologies and languages and systems over the years, and I believe that given a problem, I have the tools to go about finding the best way to solve said problem. It's what I love to do!
        </p>
        <h3 id="jui-jitsu">Jui-Jitsu (Brazillian)</h3>
        <p>
          I've always been an athlete and a competitor. I've done a fair amount of runnning, competed in triathlons, won work league tennis championships, won office ping pong championships, and have generally tried to maintain a certain level of fitness. However, all that pales in comparison to the gentle art of jui-jitsu, specifically Brazilian jui-jitsu.
        </p>
        <p>
          I started trainging BJJ in May of 2019, and I fell in love with the sport on day one! The best I can describe it in words is a human chess match. Not only is it full body and cardio workout, but it works the mind too. You have to be sharp on all fronts.
          In BJJ, technique is everything. There is a vastness of moves, setups, and positions and this is why I think that it is so intruiging. It is like chess, but with humans.
        </p>
        <p>
          Actually, John Danaher says it perfectly:
        </p>
        <blockquote cite="https://bjjmind.wordpress.com/2012/09/09/rickson-gracie-and-john-danaher-agree/">
          <p>The deepest benefits of Jiu Jitsu come off the mat.  It encourages a world-view based upon the idea of rational problem solving.  Jiu Jitsu is all about solving problems that are rapidly changing under stress, and that gives you an ability to identify the crux of the problem in front of you, even in a stressful situation and adapt your body and tactics to overcome that problem and to continue overcoming it as the problem itself changes.  It encourages you to use a very rational trial and error method, basically the same method that science uses to overcome these problems.  So it gives you this sort of problem solving mindset, which I think applies throughout life itself.</p>
          <footer>—John Danaher, <cite>From Open Mat Radio interview</cite></footer>
        </blockquote>
        <h3 id="dog-dad">Dog Dad</h3>
        <blockquote cite="https://www.goodreads.com/quotes/13267-a-dog-is-the-only-thing-on-earth-that-loves">
          <p>A dog is the only thing on earth that loves you more than he loves himself.</p>
          <footer>—Josh Billings</footer>
        </blockquote>
        <p>
          During thanksgiving of 2016, my fiance at the time, now my wife, was spending the holiday with her dad while I was hours away, spending it with my family.
          She decided to go look at the puppies at our local pet store. That is when she saw her. A small, barely 6 pound brown/brindle Boston Terrier, who pooped and peed on here when she played with her!
          She called after she left the store, in tears because she was in love. How could I say no. She told the clerk to put her on hold and we went and picked her up when I returned homw from the holiday.
        </p>
        <p>
          We brought her home and named her Piper. The first thing we did was give her a bath and she pooped in the bath! We now and forever call her Piper pooper.
        </p>
        <p>
          She's still a runt for her breed, weighing 12 pounds soaking wet. She hates the water and loves treats and belly rubs. She can do a handful of tricks.
          She is very expressive. And she loves going to the dog park, for the people, not the other dogs...
        </p>
        <h3 id="travelling">Travelling</h3>
        <blockquote cite="https://www.goodreads.com/quotes/76288-travel-makes-one-modest-you-see-what-a-tiny-place">
          <p>Travel makes one modest. You see what a tiny place you occupy in the world.</p>
          <footer>—Gustave Flaubert</footer>
        </blockquote>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        <h3 id="chess">Chess</h3>
        <blockquote cite="https://en.wikiquote.org/wiki/Siegbert_Tarrasch">
          <p>Chess, like love, like music, has the power to make men happy.</p>
          <footer>—Siegbert Tarrasch, <cite>The Game of Chess (1931)</cite></footer>
        </blockquote>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
      </div>
    );
  }
}

About.propTypes = {
  setBackground: PropTypes.func.isRequired,
}

export default About;
import React from "react";
import PropTypes from 'prop-types';

class Projects extends React.Component {
  componentDidMount() {
    this.props.setBackground('secondary');
  }

  render() {
    return (
      <div className="project">
        <h2>That Celly App</h2>
        <img src="goal-light-small.gif" alt="goal light gif"></img>
        <span style={ {align: 'center', paddingTop: '8px'}}>This page is under construction. Check back later.</span>
      </div>
    );
  }
}

Projects.propTypes = {
  setBackground: PropTypes.func.isRequired,
}

export default Projects;
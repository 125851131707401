import React from "react";
import PropTypes from 'prop-types';
import UnderConstruction from "./UnderConstruction";

class Blog extends React.Component {
  componentDidMount() {
    this.props.setBackground('secondary');
  }
  
  render() {
    return (
      <UnderConstruction pageTitle="Blog"/>
    );
  }
}

Blog.propTypes = {
  setBackground: PropTypes.func.isRequired,
}

export default Blog;
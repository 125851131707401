import React from "react";
import {
  NavLink
} from 'react-router-dom';


class NavBar extends React.Component {
  render() {
    return (
      <div className="nav">
        {/* <div className="nav-control">
          <i className="fas fa-chevron-left"></i>
        </div> */}
        <div className="nav-items">
          <NavLink exact to="/" className="nav-item" activeClassName="active">
            <i className="fas fa-home fa-3x"></i>
            <span>Home</span>
          </NavLink>
          <NavLink to="/about" className="nav-item" activeClassName="active">
            <i className="far fa-address-card fa-3x"></i>
            <span>About Me</span>
          </NavLink>
          <NavLink to="/projects" className="nav-item"activeClassName="active">
            <i className="fas fa-code-branch fa-3x"></i>
            <span>Projects</span>
          </NavLink>
          <NavLink to="/blog" className="nav-item" activeClassName="active">
            <i className="nav-item fas fa-blog fa-3x"></i>
            <span>Blog</span>
          </NavLink>
        </div>
      </div>
    );
  }
}

export default NavBar;
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import NavBar from './NavBar';
import About from './About';
import Blog from './Blog';
import Home from './Home';
import Projects from './Projects';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      background: 'primary',
    };
    this.setBackground = this.setBackground.bind(this);
  }

  setBackground(background){
    this.setState({background});
  }

  render() {
    return (
      <Router>
        <NavBar/>
          <div className={`app-container bg-${this.state.background}`}>
            <Switch>
              <Route exact path="/" render={() => <Home setBackground={this.setBackground} />} />
              <Route path="/about" render={() => <About setBackground={this.setBackground} />} />
              <Route path="/projects" render={() => <Projects setBackground={this.setBackground} />}  />
              <Route path="/blog" render={() => <Blog setBackground={this.setBackground} />} />
              <Route render={() => <h1>Page not found</h1>} />
            </Switch>
          </div>
          <div className="footer">
            <div>
              <span>Built by MC. All rights reserved. | </span>
              <a href="https://www.linkedin.com/in/mitchell-cox-048b649b/"><i className="fab fa-linkedin" style={{color: '#0072B1'}}></i></a>
            </div>
          </div>
      </Router>
    );
  }
}

export default App;
